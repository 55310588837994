* {
    padding: 0;
    margin: 0;
}

.card {
    padding: 30px 30px;
    border-radius: 8px;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
}

.text-heading {
    color: #252733;
    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
    padding: 20px 0;
}

.txt {
    text-align: center;
    color: #9FA2B4;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-bottom: 70px;
}

.input-email {
    border-radius: 5px;
    border: 1px solid #B2BCCA;
    outline: none;
    padding: 10px;
    margin-bottom: 40px;
    width: 100%;
}

.form-btn {
    background: #4F71F9;
    box-shadow: 0px 4px 12px rgba(55, 81, 255, 0.24);
    border-radius: 8px;
    height: 48px;
    width: 330px;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}